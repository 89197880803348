import request from '@/utils/request'
import { iResponse } from '@/services/types'

export default () =>
  new (class {
    departmentList(): Promise<iResponse> {
      return request.get('/departments')
    }

    departmentUpdate(id: number, params: any): Promise<iResponse> {
      return request.post('/department/' + id, params)
    }

    departmentAdd(params: any): Promise<iResponse> {
      return request.put('/department/add', params)
    }

    departmentDel(id: number, params: any): Promise<iResponse> {
      return request.delete(
        '/department/' + id + '/delete?talent=' + params.talent
      )
    }
  })()
